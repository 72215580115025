import React from 'react';
import PropTypes from 'prop-types';
import Login from '@mui/icons-material/Login';

const LoginIcon = ({ height, width, ...more }) => {

	return (
		<Login
			height={height}
			width={width}
			{...more}
		/>
	);
};

LoginIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
};

export default LoginIcon;
