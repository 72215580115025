import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Feedback from '../../../../components/mui/Feedback';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { setIsLoading } from '../../../../redux/loading/actions/loadingAction';
import { setIsOpenRenewalModal, setPolicy } from '../redux/actions/policyAction';
import { setStoreRenewal } from '../services';
import { swalSuccess } from '../../../../hooks/useSweetAlert';
import swalErrors from '../../../../hooks/useErrors';
import usePermission from '../../../../hooks/usePermission';
import { initialValuesRenewal as initialValues } from '../../../../helpers/variablesInitialValues';
import rentIcon from '../../../../assets/images/user.svg';
import isSuccess from '../../../../helpers/isSuccess';

const RenewalModalComponent = () => {
  const renewalStore = usePermission('policies.renewals.store');
  const dispatch = useDispatch();
  const policy = useSelector( ({ policy }) => policy.policy);
  const isOpen = useSelector( ({ policy }) => policy.isOpenRenewalModal);

  /**
   *
   * @description Validation schema
   * @return object
   *
   */
  const validationSchema = () => (
    Yup.object({
      rent: Yup.number().required('La renta es requerida')
    })
  );

  /**
   *
   * @description Execute submit of the form
   * @param values
   * @param resetForm
   * @param setValues
   * @return dispatch
   *
   */
  const handleSubmit = async (values, { resetForm, setValues }) => {
    if (renewalStore) {

      dispatch(setIsLoading(true));

      try {
        const param = {
          poliza_id: policy.id,
          monto_renta: values.rent,
          observaciones: values.reason
        };

        const { data } = await setStoreRenewal(param);

        if (isSuccess(data.status)) {
          resetForm({ values: initialValues});
          setValues(initialValues);
          handleCloseModal();
          await swalSuccess('Renovación',  `Su solicitud ha sido enviada, uno de nuestros asesores los contactará pronto.<br> Puedes cerrar esta ventana`);
        }
      } catch (error) {
        dispatch(setIsLoading(false));
        await swalErrors(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  }

  /**
   *
   * @description Execute close renewal modal state
   * @return dispatch
   *
   */
  const handleCloseModal = () => {
    dispatch( setIsOpenRenewalModal(false) );
    dispatch( setPolicy({}) );
  }

  return (
    <Feedback.Modal
      maxWidth="sm"
      isOpen={isOpen}
      isClose={handleCloseModal}
      title="Renovación"
      hasSubTitle={true}
      subTitle={`Folio ${policy.folio}`}
    >
      <div className="card-body bg-white p-0 text-center">
        <div className="row m-0 p-0 pt-2 pb-2">
          <div className="col-sm-12 pt-4 pl-4 pr-4 pb-2">
            <div className="font-family-poppins-bold fs-16 text-dark text-uppercase">
              <img
                alt=""
                className="mr-2"
                src={rentIcon}
                width="20"
              />
              Renovación
            </div>
          </div>
          <div className="col-sm-12 pb-4">
            <div className="font-family-poppins-medium fs-16 text-dark text-wrap">
              { policy.direccion_name }
            </div>
          </div>
        </div>
        <div className="row m-0 p-0 pt-2 pb-2 bg-light text-center">
          <div className="col-sm-12 pt-4 pl-4 pr-4 pb-2">
            <div className="font-family-poppins-bold fs-16 text-dark">
              <img
                alt=""
                className="mr-2"
                src={rentIcon}
                width="20"
              />
              IMPORTE DE RENTA ACTUAL
            </div>
          </div>
          <div className="col-sm-12 pb-4">
            <div className="font-family-poppins-medium fs-16 text-dark text-wrap">
              ${ policy.monto_renta }
            </div>
          </div>
        </div>
        <div className="row m-0 p-4">
          <div className="col-sm-12 mt-4 mb-4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
                dirty
              }) => (
                <Form noValidate>
                  <div className="row">
                    <div className="col-sm-12 mb-4">
                      <div className="font-family-poppins-bold fs-16 text-dark">
                        <img
                          alt=""
                          className="mr-2"
                          src={rentIcon}
                          width="20"
                        />
                        NUEVO IMPORTE DE RENTA
                      </div>
                    </div>
                    <div className="col-sm-12 mb-4">
                      <Inputs.TextBox
                        name="rent"
                        placeholder=""
                        value={values.rent}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        startAdornment={<Layout.Icons.AttachMoneyIcon height={18} width={18} />}
                        hasError={errors.rent && touched.rent ? true : false}
                        errorMessage={errors.rent && touched.rent ? errors.rent : ''}
                        className="font-family-poppins-regular"
                        type="number"
                      />
                    </div>
                    <div className="col-sm-12 mb-4">
                      <div className="font-family-poppins-bold fs-16 text-dark">
                        OBSERVACIONES AL CONTRATO
                      </div>
                    </div>
                    <div className="col-sm-12 mb-4">
                      <Inputs.TextArea
                        name="reason"
                        placeholder="Ingrese observaciones..."
                        value={values.reason}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className="font-family-poppins-regular"
                      />
                    </div>
                    <div className="col-sm-12 d-flex justify-content-center">
                      {renewalStore && (
                        <Inputs.LoadingButton
                          isDisabled={!(dirty && isValid)}
                          isLoading={false}
                          isLoadingPosition="start"
                          startIcon={<Layout.Icons.SendIcon height={18} width={18} />}
                          className={`${(dirty && isValid) ? 'btn-primary' : 'btn-grey'} font-family-poppins-medium fs-16`}
                          label="Solicitar renovación"
                          type="submit"
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-sm-12 mb-4">
            <div className="font-family-poppins-medium fs-16 text-dark">
              Para más información favor de
            </div>
            <div className="font-family-poppins-medium fs-16 text-dark">
              comunicarse a su sucursal
            </div>
          </div>
        </div>
      </div>
    </Feedback.Modal>
  );
};

export default RenewalModalComponent;
