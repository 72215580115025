import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import favicon from '../../../assets/images/favicon/favicon.png';

const Page = forwardRef(({ title, meta = null, children }, ref) => (
	<>
		<Helmet>
			<title>{`${title} | ${process.env.REACT_APP_TITLE}`}</title>
			{meta}
			<link rel="icon" href={favicon} />
			<link rel="apple-touch-icon" href={favicon} />
		</Helmet>

		<Box ref={ref}>
			{children}
		</Box>
	</>
));

Page.propTypes = {
	title: PropTypes.string.isRequired,
	meta: PropTypes.any,
	children: PropTypes.any.isRequired
};

export default Page;
