import React from 'react';
import PropTypes from 'prop-types';
import AttachMoney from '@mui/icons-material/AttachMoney';

const AttachMoneyIcon = ({ height, width, ...more }) => {

  return (
    <AttachMoney
      height={height}
      width={width}
      {...more}
    />
  );
};

AttachMoneyIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default AttachMoneyIcon;
