import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';

const Button = (props) => {
  const {
    variant = 'contained',
    size = 'medium',
    color = 'inherit',
    label,
    startIcon = '',
    endIcon = '',
    onClick = null,
    ...more
  } = props;

  return (
    <MuiButton
      variant={variant}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      {...more}
    >
      {label}
    </MuiButton>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.any,
  label: PropTypes.string.isRequired,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  onClick: PropTypes.func
};

export default Button;
