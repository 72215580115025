import React from 'react'
import PropTypes from 'prop-types'
import MuiPagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

const Pagination = ({ size = 'medium', page, count, onChange }) => {

	return (
		<Stack spacing={2}>
			<MuiPagination
				size={size}
				page={page}
				count={count}
				onChange={onChange}
				variant="outlined"
				showFirstButton
				showLastButton
			/>
		</Stack>
	);
}

Pagination.propTypes = {
	size: PropTypes.string,
	page: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired
}

export default Pagination
