import Button from './Button';
import LoadingButton from './LoadingButton';
import TextArea from './TextArea';
import TextBox from './TextBox';
import TextBoxPassword from './TextBoxPassword';

const index = {
  Button,
  LoadingButton,
  TextArea,
  TextBox,
  TextBoxPassword
};

export default index;
