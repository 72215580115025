import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/mui/Layout';
import PolicyCardComponent from './components/PolicyCardComponent';
import { setIsLoading } from '../../../redux/loading/actions/loadingAction';
import { setPages, setArrayList } from './redux/actions/policyAction';
import { getArrayList } from './services';
import swalErrors from '../../../hooks/useErrors';
import isSuccess from '../../../helpers/isSuccess';

const PolicyPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector( ({ loading }) => loading.isLoading);
  const perPage = useSelector( ({ policy }) => policy.perPage);
  const page = useSelector( ({ policy }) => policy.page);
  const arrayList = useSelector( ({ policy }) => policy.arrayList);

  /**
   *
   * @description Execute endpoint array list of amenities
   * @return dispatch
   *
   */
  const handleArrayList = async () => {
    dispatch(setIsLoading(page === 1));

    try {
      const param = {
        perPage: perPage,
        page: page
      };

      const {data} = await getArrayList(param);

      if (isSuccess(data.status)) {
        dispatch(setPages(data.data.pages));
        dispatch(setArrayList(data.data.records));
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      await swalErrors(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    handleArrayList();
  }, []);

  return (
    <Layout.Page title="Polizas">
      <div className="row mt-4">
        {(!isLoading && arrayList.length > 0) && (
          arrayList.map((item, index) => (
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4">
              <PolicyCardComponent
                key={index}
                policy={item}
              />
            </div>
          ))
        )}
      </div>
    </Layout.Page>
  );
};

export default PolicyPage;
