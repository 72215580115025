import endPoints from './endPoints';
import axios from '../../../hooks/useAxios';

const {
	signIn,
	forgotPassword,
	passwordVerification,
	passwordResendVerification,
	passwordReset,
	signOut,
} = endPoints;

/**
 *
 * @description Execute endpoint login
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setLogIn = async (data) => {
	axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
	axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

	return await axios.post(signIn, data);
}

/**
 *
 * @description Execute endpoint forgot password
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setForgotPassword = async (data) => {
	axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
	axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

	return await axios.post(forgotPassword, data);
}

/**
 *
 * @description Execute endpoint password verification
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const getPasswordVerification = async (id) => {
	axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
	axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

	return await axios.get(`${passwordVerification}${id}`);
}

/**
 *
 * @description Execute endpoint password resend verification
 * @param id
 * @method get
 * @return jsonResponse
 *
 */
export const setPasswordResendVerification = async (id) => {
	axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
	axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

	return await axios.get(`${passwordResendVerification}${id}`);
}

/**
 *
 * @description Execute endpoint password reset
 * @param id
 * @param data
 * @method put
 * @return jsonResponse
 *
 */
export const setPasswordReset = async (id, data) => {
	axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
	axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

	return await axios.put(`${passwordReset}${id}`, data);
}

/**
 *
 * @description Execute endpoint logout
 * @method get
 * @return jsonResponse
 *
 */
export const setLogOut = async () => {
	const value = localStorage.getItem('auth');
	const auth = JSON.parse(value) ?? undefined;

	axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
	axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

	axios.defaults.headers.common['Authorization'] = `${auth.tokenType ?? ''} ${auth.accessToken ?? ''}`;

	return await axios.get(signOut);
}
