import Swal from 'sweetalert2';
import { setReset } from '../pages/auth/redux/actions/authAction';

export const swalSuccess = async (title, message) => (
	await Swal.fire({
		title: title,
		html: message,
		icon: 'success',
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: true,
		confirmButtonColor: '#232323',
		confirmButtonText: 'Cerrar'
	})
);

export const swalError = async (message, status = null) => {
	if (status === 401) {
		localStorage.clear();
		setReset();
	}

	return await Swal.fire({
		title: 'Error',
		html: message,
		icon: 'error',
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: true,
		confirmButtonColor: '#3085d6',
		confirmButtonText: 'Aceptar'
	});
};

export const swalConfirm = async (message) => (
	await Swal.fire({
		title: '',
		html: message,
		icon: 'warning',
		allowOutsideClick: false,
		allowEscapeKey: false,
		showConfirmButton: true,
		confirmButtonColor: '#3085d6',
		confirmButtonText: 'Aceptar',
		showDenyButton: true,
		denyButtonText: 'Cancelar',
		reverseButtons: true
	}).then(({ isDenied }) => {
		if (isDenied) {
			return false
		}
	})
);
