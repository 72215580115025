import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from '../../../redux/store';
import Layout from '../../../components/mui/Layout';
import Inputs from '../../../components/mui/Inputs';
import Feedback from '../../../components/mui/Feedback';
import HeaderComponent from '../components/HeaderComponent';
import FormComponent from './components/FormComponent';
import { getPasswordVerification, setPasswordResendVerification } from '../services';
import { setIsLoading } from '../../../redux/loading/actions/loadingAction';
import { setIsLoadingForm, setIsActiveButton, setColorStatus, setTextStatus, setIsActiveAlert, setIsActiveForm, setResetPasswordReset } from '../redux/actions/passwordResetAction';
import { swalSuccess } from '../../../hooks/useSweetAlert';
import swalErrors from '../../../hooks/useErrors';
import isSuccess from '../../../helpers/isSuccess';
import '../Auth.scss';

const PasswordResetPage = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const uID = params.uID;
	const isLoading = useSelector( ({ loading }) => loading.isLoading);
	const isLoadingForm = useSelector( ({ passwordReset })=> passwordReset.isLoadingForm);
	const isActiveButton = useSelector( ({ passwordReset }) => passwordReset.isActiveButton);
	const isActiveForm = useSelector( ({ passwordReset }) => passwordReset.isActiveForm);
	const isActiveAlert = useSelector( ({ passwordReset }) => passwordReset.isActiveAlert);
	const colorStatus = useSelector( ({ passwordReset }) => passwordReset.colorStatus);
	const textStatus = useSelector( ({ passwordReset }) => passwordReset.textStatus);
	
	/**
	 *
	 * @description Execute verification of the password reset
	 * @return dispatch
	 *
	 */
	const handleVerification = async () => {
		dispatch( setIsLoading(true) );
		
		try {
			const { data } = await getPasswordVerification(uID);
			
			if (isSuccess(data.status)) {
				dispatch( setColorStatus('') );
				dispatch( setTextStatus('') );
				dispatch( setIsActiveButton(false) );
				dispatch( setIsActiveAlert(false) );
				dispatch( setIsActiveForm(true) );
			}
		} catch ({ response }) {
			dispatch( setIsActiveButton(response.data.errors[0] === 'El usuario no se encuentra registrado' ? false : true) );
			dispatch( setColorStatus('danger') );
			dispatch( setTextStatus(response.data.errors[0]) );
			dispatch( setIsActiveAlert(true) );
			dispatch( setIsActiveForm(false) );
			dispatch( setIsLoading(false) );
		} finally {
			dispatch( setIsLoading(false) );
		}
	};
	
	useEffect(() => {
		handleVerification();
	}, [uID]);
	
	/**
	 *
	 * @description Execute resend verification of the password reset
	 * @return dispatch
	 *
	 */
	const handleResendVerification = async () => {
		dispatch( setIsLoadingForm(true) );
		
		try {
			const { data } = await setPasswordResendVerification(uID);
			
			if (isSuccess(data.status)) {
				await swalSuccess('Solicitud exitosa', data.data);
				dispatch( setResetPasswordReset() );
				await history.push('/');
			}
		} catch (error) {
			await swalErrors(error);
			dispatch( setIsLoadingForm(false) );
		} finally {
			dispatch( setIsLoadingForm(false) );
		}
	};
	
	return (
		<Layout.Page title="Cambio de contraseña">
			<div className="form-container">
				<div className="card">
					<div className="card-body">
						<HeaderComponent />
						<div className="row">
							<div className="col-sm-12 mb-4">
								<Layout.CardContent className="m-0 pt-4 pr-4 pl-4 pb-0">
									{!isLoading && isActiveAlert && (
										<div className={`animated animate__fadeIn mb-2 alert alert-${colorStatus}`}>
											<div className="font-family-roboto-medium fs-6 text-center">
												{textStatus}
											</div>
										</div>
									)}
									{isActiveButton && (
										<Inputs.LoadingButton
											variant="contained"
											isLoading={isLoadingForm}
											isLoadingPosition="start"
											startIcon={<Layout.Icons.MailIcon height={18} width={18} />}
											label="Reenviar solicitud"
											type="button"
											className="animated animate__fadeIn font-family-poppins-medium fs-16 btn-primary w-100"
											onClick={handleResendVerification}
										/>
									)}
									{isActiveForm && (
										<FormComponent uID={uID} />
									)}
								</Layout.CardContent>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Feedback.ModalLoading
				isOpen={isLoading}
				title="Cargando..."
				content="Espere un momento por favor..."
			/>
		</Layout.Page>
	);
};

export default PasswordResetPage;
