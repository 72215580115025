import ReactJwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isNotEmptyValue from '../helpers/isNotEmptyValue';

const usePermission = (permission) => {
  const accessToken = useSelector(({ auth }) => auth.accessToken);

  if (isNotEmptyValue(accessToken)) {
    const decode = ReactJwtDecode(accessToken);

    if (isNotEmptyValue(decode.permissions)) {
      return decode.permissions.includes(permission);
    }
  }

  return false;
};

usePermission.propTypes = {
  param: PropTypes.string.isRequired
};

export default usePermission;
