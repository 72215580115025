import React from 'react';
import PropTypes from 'prop-types';
import Visibility from '@mui/icons-material/Visibility';

const VisibilityIcon = ({ height, width, ...more }) => {

  return (
    <Visibility
      height={height}
      width={width}
      {...more}
    />
  );
};

VisibilityIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default VisibilityIcon;
