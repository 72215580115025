const ROOT = 'auth/';

export default {
	signIn: `${ROOT}login`,
	signOut: `${ROOT}logout`,
	forgotPassword: `${ROOT}forgot-password`,
	passwordVerification: `${ROOT}password-verification/`,
	passwordResendVerification: `${ROOT}password-resend-verification/`,
	passwordReset: `${ROOT}password-reset/`,
};
