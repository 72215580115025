import { swalError } from './useSweetAlert';

const useErrors = error => {
	const result = JSON.parse(error.request.response);
	const { status } = error.request;
	let response = '';

	if (status === 422) {
		const errors = Object.values(result.errors);

		if (errors.length > 0) {
			let content = '';

			for (const error of errors) {
				content += `<li class="border-0 d-flex text-start">${error.toString()}</li>`;
			}

			response = `<ul class="list-group border-0 bg-transparent">${content}</ul>`;
		}
	}

	if (status === 500) {
		response = `<ul class="list-group border-0 bg-transparent">
      <li class="border-0 d-flex text-start">${result.message}</li>
      <li class="border-0 d-flex text-start">${result.file}</li>
      <li class="border-0 d-flex text-start">Line ${result.line}</li>
    </ul>`;
	}

	const HTTP = {
		401: 'Usted no se encuentra autenticado',
		403: 'Usted no tiene autorización para este método',
		404: 'La url específicada no se encuentra',
		405: 'El método específicado no es válido',
		409: 'Hay un conflicto con el recurso inténtalo de nuevo',
		422: response,
		500: response
	};

	if (status === 401) {
		return swalError(HTTP[status], 401);
	}

	return swalError(HTTP[status]);
};

export default useErrors;
