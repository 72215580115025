import React from 'react';
import PropTypes from 'prop-types';
import Feedback from '../Feedback';
import { arrayLoading } from '../../../helpers/variablesColumns';

const TableRowLoading = ({ columns }) => {
	
	return (
		<>
			{arrayLoading.length > 0 && (
				arrayLoading.map((value, index) => (
					<tr
						key={index}
						className="m-0 p-0"
					>
						{columns.length > 0 && (
							columns.map(({ width }, number) => (
								<td
									key={number}
									className="m-0 pt-1 pb-1 pl-2 pr-2"
									width={width}
								>
									<Feedback.Skeleton
										variant="text"
										width="100%"
										height={30}
									/>
								</td>
							))
						)}
					</tr>
				))
			)}
		</>
	)
}

TableRowLoading.propTypes = {
	columns: PropTypes.array.isRequired
}

export default TableRowLoading
