import endPoints from './endPoints';
import axios from '../../../../hooks/useAxios';

const {
  arrayList,
  storeClaim,
  storeRenewal,
} = endPoints;

/**
 *
 * @description Execute endpoint array list of policies
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const getArrayList = async (data) => {
  const value = localStorage.getItem('auth');
  const auth = JSON.parse(value) ?? undefined;

  axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
  axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

  axios.defaults.headers.common['Authorization'] = `${auth.tokenType ?? ''} ${auth.accessToken ?? ''}`;

  return await axios.post(arrayList, data);
}

/**
 *
 * @description Execute endpoint store claim
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setStoreClaim = async (data) => {
  const value = localStorage.getItem('auth');
  const auth = JSON.parse(value) ?? undefined;

  axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
  axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

  axios.defaults.headers.common['Authorization'] = `${auth.tokenType ?? ''} ${auth.accessToken ?? ''}`;

  return await axios.post(storeClaim, data);
}

/**
 *
 * @description Execute endpoint store renewal
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setStoreRenewal = async (data) => {
  const value = localStorage.getItem('auth');
  const auth = JSON.parse(value) ?? undefined;

  axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
  axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

  axios.defaults.headers.common['Authorization'] = `${auth.tokenType ?? ''} ${auth.accessToken ?? ''}`;

  return await axios.post(storeRenewal, data);
}
