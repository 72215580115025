import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import Layout from '../Layout';

const CardCustomized = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		border: 'none !important',
		borderRadius: '.8rem !important',
		boxShadow: 'none !important'
	}
}));

const Modal = ({ maxWidth, isOpen, isClose, title = '', hasSubTitle = false, subTitle = '', children, hasAction = false, contentActions = null, ...more }) => {

	return (
		<CardCustomized
			disableEscapeKeyDown
			fullWidth={true}
			maxWidth={maxWidth}
			open={isOpen}
			{...more}
		>
			<DialogTitle className="bg-dark p-4">
				<div className="font-family-poppins-semibold fs-18 mx-auto text-white text-center">
					{title}
				</div>
				{hasSubTitle && (
					<div className="font-family-poppins-semibold fs-18 mx-auto text-white text-center">
						{subTitle}
					</div>
				)}
				<Layout.IconButton
					color="inherit"
					size="small"
					onClick={isClose}
					className="bg-white icon-close-modal"
				>
					<Layout.Icons.CloseIcon
						height={18}
						width={18}
						className="text-dark"
					/>
				</Layout.IconButton>
			</DialogTitle>
			<DialogContent
				className="m-0 p-0"
				dividers
				{...more}
			>
				{children}
			</DialogContent>
			{hasAction && (
				<DialogActions>
					{contentActions}
				</DialogActions>
			)}
		</CardCustomized>
	);
};

Modal.propTypes = {
	maxWidth: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	hasSubTitle: PropTypes.bool,
	subTitle: PropTypes.string,
	children: PropTypes.any.isRequired,
	hasAction: PropTypes.bool,
	contentActions: PropTypes.any
};

export default Modal;
