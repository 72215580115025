import React from 'react';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Layout from '../Layout';

const ScrollTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = () => window.scroll({top: 0, left: 0, behavior: 'smooth' });

  return (
    <Zoom direction="down" in={trigger}>
      <Fab
        color="inherit"
        size="medium"
        aria-label="scroll back to top"
        onClick={handleClick}
        role="presentation"
        className="position-fixed back-to-top bg-dark"
      >
        <Layout.Icons.KeyboardArrowUpIcon
          height={28}
          width={28}
          className="text-white"
        />
      </Fab>
    </Zoom>
  );
};

export default ScrollTop;
