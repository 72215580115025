import React from 'react';
import PropTypes from 'prop-types';
import Inputs from '../Inputs';
import Layout from '../Layout';

const TableSearch = ({ permissionList, permissionButton, value, handleChange, handleIsOpenModal, handleKeyPressArrayList }) => {
	return (
		<div className="row mb-3">
			<div className="col-sm-12 col-md-12 col-lg-4 mb-1 d-flex justify-content-start">
				<Inputs.TextBox
					type="text"
					variant="outlined"
					size="small"
					name="search"
					label="Buscar"
					placeholder="Ingrese la búsqueda..."
					hasLabelFixed={true}
					value={value}
					onChange={handleChange}
					onKeyPress={handleKeyPressArrayList}
					className="font-family-roboto-medium text-dark fs-18 w-100"
				/>
			</div>
			<div className="col-sm-12 col-md-12 col-lg-4"></div>
			<div className="col-sm-12 col-md-12 col-lg-4 mb-1 d-flex justify-content-end">
				<Inputs.Button
					variant="contained"
					label="Nuevo"
					startIcon={<Layout.Icons.AddCircleIcon width={20} height={20} />}
					onClick={handleIsOpenModal}
					className="bg-primary font-family-roboto-regular fs-16"
				/>
			</div>
		</div>
	)
}

TableSearch.propTypes = {
	permissionList: PropTypes.string.isRequired,
	permissionButton: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleIsOpenModal: PropTypes.func.isRequired,
	handleKeyPressArrayList: PropTypes.func.isRequired
};

export default TableSearch;
