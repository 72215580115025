import endPoints from './endPoints';
import axios from '../../../../../hooks/useAxios';

const { updateProfilePassword } = endPoints;

/**
 *
 * @description Execute endpoint update profile password
 * @param data
 * @method post
 * @return jsonResponse
 *
 */
export const setUpdateProfilePasswordById = async (data) => {
	const value = localStorage.getItem('auth');
	const auth = JSON.parse(value) ?? undefined;

	axios.defaults.headers.common["Accept"] = "application/json;charset=UTF-8";
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
	axios.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

	axios.defaults.headers.common['Authorization'] = `${auth.tokenType ?? ''} ${auth.accessToken ?? ''}`;

	return await axios.post(updateProfilePassword, data);
}
