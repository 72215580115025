import React from 'react'
import PropTypes from 'prop-types'
import MuiTypography from '@mui/material/Typography'

const Typography = ({ children, ...more  }) => {
	
	return (
		<MuiTypography
			{...more}
		>
			{children}
		</MuiTypography>
	)
}

Typography.propTypes = {
	children: PropTypes.any.isRequired
}

export default Typography
