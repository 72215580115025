import React from 'react';
import { useRoutes } from 'react-router-dom';
import LoginPage from '../pages/auth/LoginPage';
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage';
import PasswordResetPage from '../pages/auth/PasswordResetPage';
import ErrorPage from '../pages/errors/ErrorPage';

const AuthRoutes = () => {
  return useRoutes([
    {
      index: true,
      path: '',
      element: <LoginPage />
    },
    {
      index: false,
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      index: false,
      path: 'password-reset/:uID',
      element: <PasswordResetPage />,
    },
    {
      index: false,
      path: '*',
      element: <ErrorPage />
    }
  ]);
};

export default AuthRoutes;
