import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Feedback from '../../../../components/mui/Feedback';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { setIsLoading } from '../../../../redux/loading/actions/loadingAction';
import { setIsOpenClaimModal, setPolicy } from '../redux/actions/policyAction';
import { setStoreClaim } from '../services';
import { swalSuccess } from '../../../../hooks/useSweetAlert';
import swalErrors from '../../../../hooks/useErrors';
import usePermission from '../../../../hooks/usePermission';
import { initialValuesClaim as initialValues } from '../../../../helpers/variablesInitialValues';
import isSuccess from '../../../../helpers/isSuccess';

const ClaimModalComponent = () => {
  const claimStore = usePermission('policies.claims.store');
  const dispatch = useDispatch();
  const policy = useSelector( ({ policy }) => policy.policy);
  const isOpen = useSelector( ({ policy }) => policy.isOpenClaimModal);

  /**
   *
   * @description Validation schema
   * @return object
   *
   */
  const validationSchema = () => (
    Yup.object({
      phone: Yup.string().required('El teléfono es requerido'),
      subject: Yup.string().required('El asunto es requerido'),
      reason: Yup.string().required('La descripción es requerida')
    })
  );

  /**
   *
   * @description Execute submit of the form
   * @param values
   * @param resetForm
   * @param setValues
   * @return dispatch
   *
   */
  const handleSubmit = async (values, { resetForm, setValues }) => {
    if (claimStore) {

      dispatch(setIsLoading(true));

      try {
        const param = {
          poliza_id: policy.id,
          telefono: values.phone,
          asunto: values.subject,
          observaciones: values.reason
        };

        const { data } = await setStoreClaim(param);

        if (isSuccess(data.status)) {
          resetForm({ values: initialValues});
          setValues(initialValues);
          handleCloseModal();
          await swalSuccess('Reclamación',  `Su solicitud ha sido enviada, uno de nuestros asesores los contactará pronto.<br> Puedes cerrar esta ventana`);
        }
      } catch (error) {
        dispatch(setIsLoading(false));
        await swalErrors(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  }

  /**
   *
   * @description Execute close reclamación modal state
   * @return dispatch
   *
   */
  const handleCloseModal = () => {
    dispatch( setIsOpenClaimModal(false) );
    dispatch( setPolicy({}) );
  }

  return (
    <Feedback.Modal
      maxWidth="sm"
      isOpen={isOpen}
      isClose={handleCloseModal}
      title="Reclamaciones"
      hasSubTitle={true}
      subTitle={`Folio ${policy.folio}`}
    >
      <div className="row m-4 p-4">
        <div className="col-sm-12 mt-4 mb-4">
          <div className="font-family-poppins-medium fs-16 text-dark text-center">
            Para abrir una reclamación llene los siguientes datos
          </div>
        </div>
        <div className="col-sm-12 mt-4 mb-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              dirty
            }) => (
              <Form noValidate>
                <div className="row">
                  <div className="col-sm-12 mb-4">
                    <div className="font-family-poppins-bold fs-16 text-dark">
                      <span className="text-primary">*</span> Teléfono de contacto
                    </div>
                  </div>
                  <div className="col-sm-12 mb-4">
                    <Inputs.TextBox
                      name="phone"
                      placeholder="Ingrese teléfono de contacto..."
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={errors.phone && touched.phone ? true : false}
                      errorMessage={errors.phone && touched.phone ? errors.phone : ''}
                      className="font-family-poppins-regular"
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 mb-4">
                    <div className="font-family-poppins-bold fs-16 text-dark">
                      <span className="text-primary">*</span> Asunto de la Reclamación
                    </div>
                  </div>
                  <div className="col-sm-12 mb-4">
                    <Inputs.TextBox
                      name="subject"
                      placeholder="Ingrese asunto de la reclamación..."
                      value={values.subject}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={errors.subject && touched.subject ? true : false}
                      errorMessage={errors.subject && touched.subject ? errors.subject : ''}
                      className="font-family-poppins-regular"
                      type="text"
                    />
                  </div>
                  <div className="col-sm-12 mb-4">
                    <div className="font-family-poppins-bold fs-16 text-dark">
                      <span className="text-primary">*</span> Descripción de la Reclamación
                    </div>
                  </div>
                  <div className="col-sm-12 mb-4">
                    <Inputs.TextArea
                      name="reason"
                      placeholder="Ingrese descripción de la reclamación..."
                      value={values.reason}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={errors.reason && touched.reason ? true : false}
                      errorMessage={errors.reason && touched.reason ? errors.reason : ''}
                      className="font-family-poppins-regular"
                    />
                  </div>
                  <div className="col-sm-12 d-flex justify-content-center">
                    {claimStore && (
                      <Inputs.LoadingButton
                        isDisabled={!(dirty && isValid)}
                        isLoading={false}
                        isLoadingPosition="start"
                        startIcon={<Layout.Icons.SendIcon height={18} width={18} />}
                        className={`${(dirty && isValid) ? 'btn-primary' : 'btn-grey'} font-family-poppins-medium fs-16 mr-2`}
                        label="Enviar datos"
                        type="submit"
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Feedback.Modal>
  );
};

export default ClaimModalComponent;
