import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { history } from '../../../../redux/store';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { setLogIn } from '../../services';
import { setIsLoading } from '../../../../redux/loading/actions/loadingAction';
import { setIsLoadingForm, setIsAuthenticated, setTokenType, setAccessToken, setExpiresAt, setUserData } from '../../redux/actions/authAction';
import swalErrors from '../../../../hooks/useErrors';
import isSuccess from '../../../../helpers/isSuccess';
import { initialValuesLogin as initialValues } from '../../../../helpers/variablesInitialValues';

const FormComponent = () => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ auth }) => auth.isLoadingForm);

	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
  */
	const validationSchema = () => (
		Yup.object({
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido'),
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida')
		})
	);

	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		dispatch( setIsLoadingForm(true) );
		dispatch( setIsLoading(true) );

		try {
			const { data } = await setLogIn(values);

			if (isSuccess(data.status)) {
				const userData = {
					firstName: data.data.user_data.first_name,
					lastName: data.data.user_data.last_name,
					email: data.data.user_data.email,
					roleType: data.data.user_data.role_type,
					userType: data.data.user_data.user_type,
					marketer: data.data.user_data.marketer,
					signerType: data.data.user_data.signer_type,
					signer: data.data.user_data.signer,
					status: data.data.user_data.status
				};

				const initialState = {
					isLoadingForm: false,
					isAuthenticated: true,
					accessToken: data.data.access_token,
					tokenType: data.data.token_type,
					expiresAt: data.data.expires_at,
					userData
				};

				localStorage.setItem('auth', JSON.stringify(initialState));
				resetForm({ values: initialValues});
				setValues(initialValues);
				dispatch( setTokenType(data.data.token_type) );
				dispatch( setAccessToken(data.data.access_token) );
				dispatch( setExpiresAt(data.data.expires_at) );
				dispatch( setUserData(userData) );
				dispatch( setIsAuthenticated(true) );
				await history.push('/dashboard/polizas');
			}
		} catch (error) {
			await swalErrors(error);
			dispatch( setIsLoadingForm(false) );
			dispatch( setIsLoading(false) );
		} finally {
			dispatch( setIsLoadingForm(false) );
			dispatch( setIsLoading(false) );
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
			  values,
			  errors,
			  touched,
			  handleChange,
			  handleBlur,
			  isValid,
			  dirty
		  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 mb-4">
							<Inputs.TextBox
								name="email"
								label="* Email"
								placeholder="Ingrese email..."
								value={values.email}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.email && touched.email ? true : false}
								errorMessage={errors.email && touched.email ? errors.email : ''}
								className="font-family-poppins-regular"
							/>
						</div>
						<div className="col-sm-12 mb-4">
							<Inputs.TextBoxPassword
								name="password"
								label="* Contraseña"
								placeholder="Ingrese contraseña..."
								value={values.password}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.password && touched.password ? true : false}
								errorMessage={errors.password && touched.password ? errors.password : ''}
								className="font-family-poppins-regular"
							/>
						</div>
						<div className="col-sm-12 d-flex justify-content-center">
							<Inputs.LoadingButton
								isDisabled={!(dirty && isValid)}
								isLoading={isLoadingForm}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.LoginIcon height={18} width={18} />}
								className={`${(dirty && isValid) ? 'btn-primary' : 'btn-grey'} font-family-poppins-medium fs-16`}
								label="Acceder"
								type="submit"
							/>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormComponent;
