import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom';
import PublicRouter from './PublicRouter';
import ProtectedRouter from './ProtectedRouter';
import { setReset } from '../pages/auth/redux/actions/authAction';

const AppRouter = ({ history }) => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector( ({ auth }) => auth.isAuthenticated);

	/**
	 *
	 * @description Execute refresh of the system as a guard
	 * @return void
	 *
	 */
	const handleRefresh = () => {
		const path = history.location.pathname;

		if (isAuthenticated) {
			if (path === '/' || '/dashboard/polizas') {
				history.push('/dashboard/polizas');
			} else {
				history.push(path);
			}
		} else {
			handleReset();

			history.push(path);
		}
	};

	/**
	 *
	 * @description Execute reset global of the system
	 * @return void
	 *
	 */
	const handleReset = async () => {
		localStorage.clear();
		dispatch( setReset() );
	};

	useEffect(() => {
		handleRefresh();
	}, [history, isAuthenticated]);

	return (
		<HistoryRouter history={history}>
			<Routes>
				<Route path="/*" element={<PublicRouter />} />
				<Route path="/dashboard/*" element={<ProtectedRouter />} />
			</Routes>
		</HistoryRouter>
	);
};

AppRouter.propTypes = {
	history: PropTypes.object.isRequired
};

export default AppRouter;
