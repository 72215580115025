import React from 'react';
import Layout from '../../../components/mui/Layout';
import Logo from '../../../components/mui/Logo'
import './Header.scss';

const HeaderComponent = () => {

	return (
		<div className="row">
			<div className="col-sm-12 mb-4">
				<Layout.CardHeader
					title={<Logo className="d-flex mx-auto text-center img-logo" />}
				/>
			</div>
		</div>
	);
};

export default HeaderComponent;

