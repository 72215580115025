import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const CustomTooltip = styled(({ className, ...props }) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}))

const ChildrenComponent = forwardRef(function ChildrenComponent(props, ref) {
	return (<span {...props} ref={ref}>{props.children}</span>)
})

const Tooltip = ({ title, placement, children }) => {
	
	return (
		<CustomTooltip
			arrow
			title={title}
			placement={placement}
		>
			<ChildrenComponent>
				{children}
			</ChildrenComponent>
		</CustomTooltip>
	)
}

Tooltip.propTypes = {
	title: PropTypes.string.isRequired,
	placement: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired
}

export default Tooltip
