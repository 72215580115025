import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../components/mui/Layout';
import HeaderComponent from '../../auth/components/HeaderComponent';
import '../../auth/Auth.scss';

const ErrorPage = () => {
	const isAuthenticated = useSelector(({ auth })  => auth.isAuthenticated);

	useEffect(() => {
	}, [isAuthenticated]);

	return (
		<Layout.Page title="Error 404">
			<div className="form-container">
				<div className="card">
					<div className="card-body">
						<HeaderComponent />
						<div className="row">
							<div className="col-sm-12 mb-4">
								<h1 className="font-family-poppins-medium fs-1 text-danger text-center">
									404
								</h1>
								<h6 className="font-family-poppins-regular fs-18 text-dark text-center">
									Lo sentimos pero no pudimos encontrar la página que buscas
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout.Page>
	);
};

export default ErrorPage;
