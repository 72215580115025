import * as types from '../types/policyType';
import { OBJECT_POLICY } from '../types/policyType'

/**
 *
 * @description Set reset policy state
 * @return dispatch
 *
 */
export const setPolicyReset = () => (
  async (dispatch) => {
    await dispatch( setPerPage(20) );
    await dispatch( setPage(1) );
    await dispatch( setPages(1) );
    await dispatch( setArrayList([]) );
  }
);

/**
 *
 * @description Set perPage state
 * @param value
 * @return object
 *
 */
export const setPerPage = value => ({
  type: types.PER_PAGE_POLICY,
  payload: value
});

/**
 *
 * @description Set page state
 * @param value
 * @return object
 *
 */
export const setPage = value => ({
  type: types.PAGE_POLICY,
  payload: value
});

/**
 *
 * @description Set pages state
 * @param value
 * @return object
 *
 */
export const setPages = value => ({
  type: types.PAGES_POLICY,
  payload: value
});

/**
 *
 * @description Set arrayList state
 * @param value
 * @return object
 *
 */
export const setArrayList = value => ({
  type: types.ARRAY_LIST_POLICY,
  payload: value
});

/**
 *
 * @description Set policy state
 * @param value
 * @return object
 *
 */
export const setPolicy = value => ({
  type: types.POLICY,
  payload: value
});

/**
 *
 * @description Set open claim modal state
 * @param value
 * @return object
 *
 */
export const setIsOpenClaimModal = value => ({
  type: types.IS_OPEN_CLAIM_MODAL,
  payload: value
});

/**
 *
 * @description Set open renewal modal state
 * @param value
 * @return object
 *
 */
export const setIsOpenRenewalModal = value => ({
  type: types.IS_OPEN_RENEWAL_MODAL,
  payload: value
});
