import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import loadingReducer from './loading/reducers/loadingReducer';
import authReducer from '../pages/auth/redux/reducers/authReducer';
import forgotPasswordReducer from '../pages/auth/redux/reducers/forgotPasswordReducer';
import passwordResetReducer from '../pages/auth/redux/reducers/passwordResetReducer';
import profileReducer from '../pages/dashboard/settings/UserPage/redux/reducers/profileReducer';
import policyReducer from '../pages/dashboard/PolicyPage/redux/reducers/policyReducer';

const createReducer = asyncReducers => (
	combineReducers({
		loading: loadingReducer,
		auth: authReducer,
		forgotPassword: forgotPasswordReducer,
		passwordReset: passwordResetReducer,
		profile: profileReducer,
		policy: policyReducer,
		router: routerReducer,
		...asyncReducers
	})
);

export default createReducer;
