import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { history } from '../../../../redux/store';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { setPasswordReset } from '../../services';
import { setIsLoadingForm, setIsActiveButton, setColorStatus, setTextStatus, setIsActiveAlert, setIsActiveForm } from '../../redux/actions/passwordResetAction';
import { swalSuccess } from '../../../../hooks/useSweetAlert';
import swalErrors from '../../../../hooks/useErrors';
import isSuccess from '../../../../helpers/isSuccess';
import { initialValuesProfile as initialValues } from '../../../../helpers/variablesInitialValues';

const FormComponent = ({ uID }) => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ auth }) => auth.isLoadingForm);
	
	useEffect(() => {
	}, [uID]);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida'),
			password_confirm: Yup.string()
			.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
			.required('La confirmación de la contraseña es requerida')
			.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña')
		})
	);
	
	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		dispatch( setIsLoadingForm(true) );
		
		try {
			const { data } = await setPasswordReset(uID, values);

			if (isSuccess(data.status)) {
				resetForm({ values: initialValues});
				setValues(initialValues);
				await swalSuccess('Cambio de contraseña exitoso', data.data);
				dispatch( setColorStatus('') );
				dispatch( setTextStatus('') );
				dispatch( setIsActiveAlert(false) );
				dispatch( setIsActiveButton(false) );
				dispatch( setIsActiveForm(false) );
				await history.push('/');
			}
		} catch (error) {
			await swalErrors(error);
			dispatch( setIsLoadingForm(false) );
	  } finally {
			dispatch( setIsLoadingForm(false) );
		}
	};
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				isValid,
				dirty
		  }) => (
				<Form noValidate>
					<div className="row">
						<div className='col-sm-12 mb-4'>
							<Inputs.TextBoxPassword
								name='password'
								label='* Contraseña'
								placeholder='Ingrese contraseña...'
								value={values.password}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.password && touched.password ? true : false}
								errorMessage={errors.password && touched.password ? errors.password : ''}
								className='font-family-poppins-regular'
							/>
						</div>
						<div className="col-sm-12 mb-4">
							<Inputs.TextBoxPassword
								name="password_confirm"
								label="* Confirmar contraseña"
								placeholder="Ingrese confirmar contraseña..."
								value={values.password_confirm}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.password_confirm && touched.password_confirm ? true : false}
								errorMessage={errors.password_confirm && touched.password_confirm ? errors.password_confirm : ''}
								className="font-family-poppins-regular"
							/>
						</div>
						<div className="col-sm-12 d-flex justify-content-center">
							<Inputs.LoadingButton
								isDisabled={!(dirty && isValid)}
								isLoading={isLoadingForm}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
								className={`${(dirty && isValid) ? 'btn-primary' : 'btn-grey'} font-family-poppins-medium fs-16`}
								label="Resetear contraseña"
								type="submit"
							/>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

FormComponent.propTypes = {
	uID: PropTypes.string.isRequired
};

export default FormComponent;
