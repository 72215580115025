import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Inputs from '../../../../../components/mui/Inputs';
import Layout from '../../../../../components/mui/Layout';
import { setUpdateProfilePasswordById } from '../services';
import { setIsLoadingForm, setIsOpenModal } from '../redux/actions/profileAction';
import { swalSuccess } from '../../../../../hooks/useSweetAlert';
import swalErrors from '../../../../../hooks/useErrors';
import usePermission from '../../../../../hooks/usePermission';
import { initialValuesProfile as initialValues } from '../../../../../helpers/variablesInitialValues';
import isSuccess from '../../../../../helpers/isSuccess'

const FormPasswordReset = () => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ profile }) => profile.isLoadingForm);
	const hasUpdatePassword = usePermission('users.updatePassword');

	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			password: Yup.string()
			.min(6, 'La contraseña debe contener al menos 6 caracteres')
			.required('La contraseña es requerida'),
			passwordConfirm: Yup.string()
			.min(6, 'La confirmación de la contraseña debe contener al menos 6 caracteres')
			.required('La confirmación de la contraseña es requerida')
			.oneOf([Yup.ref('password'), null], 'La confirmación debe ser igual a la contraseña')
		})
	);

	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		if (hasUpdatePassword) {
			dispatch( setIsLoadingForm(true) );

			try {
				const param = {
					password: values.password,
					password_confirm: values.passwordConfirm,
				}

				const { data } = await setUpdateProfilePasswordById(param);

				if (isSuccess(data.status)) {
					await swalSuccess('Actualización correcta', data.data);
					resetForm({ values: initialValues});
					setValues(initialValues);
					dispatch( setIsOpenModal(false) );
				}
			} catch (error) {
				await swalErrors(error);
				dispatch( setIsLoadingForm(false) );
			} finally {
				dispatch( setIsLoadingForm(false) );
			}
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				isValid,
				dirty
		  }) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 mb-4">
							<Inputs.TextBoxPassword
								name="password"
								label="* Contraseña"
								placeholder="Ingrese contraseña..."
								value={values.password}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.password && touched.password ? true : false}
								errorMessage={errors.password && touched.password ? errors.password : ''}
								className="font-family-poppins-regular"
							/>
						</div>
						<div className="col-sm-12 mb-4">
							<Inputs.TextBoxPassword
								name="passwordConfirm"
								label="* Confirmar contraseña"
								placeholder="Ingrese confirmación..."
								value={values.passwordConfirm}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.passwordConfirm && touched.passwordConfirm ? true : false}
								errorMessage={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
								className="font-family-poppins-regular"
							/>
						</div>

						{hasUpdatePassword && (
							<div className="col-sm-12 d-flex justify-content-center">
								<Inputs.LoadingButton
									isDisabled={!(dirty && isValid)}
									isLoading={isLoadingForm}
									isLoadingPosition="start"
									startIcon={<Layout.Icons.LockOpenIcon height={18} width={18} />}
									className={`${(dirty && isValid) ? 'btn-primary' : 'btn-grey'} font-family-poppins-medium fs-16`}
									label="Resetear contraseña"
									type="submit"
								/>
							</div>
						)}
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormPasswordReset;
