import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider, Theme, useTheme } from '@mui/material/styles';

const customTheme = (outerTheme:Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: '#181A20 !important'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderWidth: '2px',
            borderColor: '#D1CFCF'
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderWidth: '2px',
              borderColor: '#FD7017'
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderWidth: '2px',
              borderColor: '#FD7017'
            },
            borderRadius: '10px',
            color: '#181A20 !important',
            height: '50px !important'
          }
        }
      }
    }
  });

const TextBox = (props) => {
  const outerTheme = useTheme();
  const {
    type = 'text',
    variant = 'outlined',
    size = 'small',
    name,
    label = '',
    placeholder,
    hasLabelFixed = true,
    value = '',
    readOnly = false,
    onChange = null,
    onBlur = null,
    onKeyPress = null,
    hasError = false,
    errorMessage = '',
    startAdornment = null,
    endAdornment = null,
    ...more
  } = props;

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <TextField
        fullWidth
        type={type}
        variant={variant}
        size={size}
        name={name}
        label={label}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: hasLabelFixed
        }}
        InputProps={{
          readOnly: readOnly,
          startAdornment: startAdornment,
          endAdornment: endAdornment
        }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        error={hasError}
        {...more}
      />
      {hasError && errorMessage !== "" && (
        <div className="mt-1 has-error-content font-family-poppins-regular fs-16 animate__animated animate__fadeIn">
          {errorMessage}
        </div>
      )}
    </ThemeProvider>
  );
};

TextBox.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  hasLabelFixed: PropTypes.bool,
  value: PropTypes.any,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any
};

export default TextBox;
