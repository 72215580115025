import React from 'react';
import PropTypes from 'prop-types';
import MuiCardHeader from '@mui/material/CardHeader';

const CardHeader = ({ avatar = null, actions = null, title, subHeader = null, ...more }) => {

	return (
		<MuiCardHeader
			avatar={avatar}
			action={actions}
			title={title}
			subheader={subHeader}
			{...more}
		/>
	);
};

CardHeader.propTypes = {
	avatar: PropTypes.any,
	actions: PropTypes.any,
	title: PropTypes.any.isRequired,
	subHeader: PropTypes.any
};

export default CardHeader;
