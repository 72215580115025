import React from 'react';
import PropTypes from 'prop-types';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const VisibilityOffIcon = ({ height, width, ...more }) => {

  return (
    <VisibilityOff
      height={height}
      width={width}
      {...more}
    />
  );
};

VisibilityOffIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default VisibilityOffIcon;
