import AttachMoneyIcon from './icons/AttachMoneyIcon';
import CloseIcon from './icons/CloseIcon';
import KeyboardArrowUpIcon from './icons/KeyboardArrowUpIcon';
import LoginIcon from './icons/LoginIcon';
import LockOpenIcon from './icons/LockOpenIcon';
import MailIcon from './icons/MailIcon';
import SaveIcon from './icons/SaveIcon';
import SearchIcon from './icons/SearchIcon';
import SendIcon from './icons/SendIcon';
import VisibilityIcon from './icons/VisibilityIcon';
import VisibilityOffIcon from './icons/VisibilityOffIcon';

const Icons = {
  AttachMoneyIcon,
  CloseIcon,
  KeyboardArrowUpIcon,
  LoginIcon,
  LockOpenIcon,
  MailIcon,
  SaveIcon,
  SearchIcon,
  SendIcon,
  VisibilityIcon,
  VisibilityOffIcon
};

export default Icons;
