import React from 'react';
import PropTypes from 'prop-types';
import Close from '@mui/icons-material/Close';

const CloseIcon = ({ height, width, ...more }) => {

	return (
		<Close
			height={height}
			width={width}
			{...more}
		/>
	);
};

CloseIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
};

export default CloseIcon;
