import React from 'react';
import PropTypes from 'prop-types';
import Save from '@mui/icons-material/Save';

const SaveIcon = ({ height, width, ...more }) => {

	return (
		<Save
			height={height}
			width={width}
			{...more}
		/>
	);
};

SaveIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
};

export default SaveIcon;
