import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as MuiNavLink } from 'react-router-dom';

const NavLink = ({ to, children, ...more }) => {
	
	return (
		<MuiNavLink
			to={to}
			{...more}
		>
			{children}
		</MuiNavLink>
	);
};

NavLink.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired
};

export default NavLink;
