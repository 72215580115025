import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Feedback from '../../../../../components/mui/Feedback';
import FormPasswordResetComponent from './FormPasswordResetComponent';
import { setIsOpenModal } from '../redux/actions/profileAction';

const ModalProfileComponent = () => {
	const dispatch = useDispatch();
	const isOpenModal = useSelector( ({ profile }) => profile.isOpenModal);

	const handleCloseModal = () => dispatch( setIsOpenModal(false) );

	return (
		<Feedback.Modal
			maxWidth="sm"
			isOpen={isOpenModal}
			isClose={handleCloseModal}
			title="Actualización del perfil"
		>
			<div className="row m-2 p-2">
				<div className="col-sm-12">
					<FormPasswordResetComponent />
				</div>
			</div>
		</Feedback.Modal>
	);
};

export default ModalProfileComponent;
