import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../components/mui/Layout';
import Feedback from '../../../components/mui/Feedback';
import Navigation from '../../../components/mui/Navigation';
import HeaderComponent from '../components/HeaderComponent'
import FormComponent from './components/FormComponent';
import '../Auth.scss';

const LoginPage = () => {
  const isLoading = useSelector( ({ loading }) => loading.isLoading);

  return (
    <Layout.Page title="Login">
      <div className="form-container">
        <div className="card">
          <div className="card-body">
            <HeaderComponent />
            <div className="row">
              <div className="col-sm-12 mb-4">
                <h6 className="card-title fw-semibold fs-16 text-dark">
                  (<span className="text-primary">*</span>) Datos requeridos
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mb-4">
                <FormComponent />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 text-center'>
                <Navigation.NavLink
                  to="/forgot-password"
                  className='font-family-roboto-regular fs-6 text-primary text-underline'
                >
                  ¿Has olvidado la contraseña?
                </Navigation.NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Feedback.ModalLoading
        isOpen={isLoading}
        title='Iniciando sesión...'
        content='Espere un momento por favor...'
      />
    </Layout.Page>
  );
};

export default LoginPage;
