import * as types from '../types/loadingType';

/**
 *
 * @description Set reset state loading action
 * @return dispatch
 *
 */
export const setResetLoading = () => (
	async (dispatch) => {
		await dispatch( setIsLoading(false) );
	}
);

/**
 *
 * @description Set state of the loading
 * @param value
 * @return object
 *
 */
export const setIsLoading = value => ({
	type: types.IS_LOADING,
	payload: value
});
