import * as types from '../types/profileType';

/**
 *
 * @description Set reset profile state
 * @return dispatch
 *
 */
export const setProfileReset = () => (
	async (dispatch) => {
		await dispatch( setIsOpenModal(false) );
		await dispatch( setIsLoadingForm(false) );
	}
);

/**
 *
 * @description Set isOpenModal state
 * @param value
 * @return object
 *
 */
export const setIsOpenModal = value => ({
	type: types.IS_OPEN_MODAL_PROFILE,
	payload: value
});

/**
 *
 * @description Set isLoadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_PROFILE,
	payload: value
});
