import React from 'react';
import PropTypes from 'prop-types';
import Send from '@mui/icons-material/Send';

const SendIcon = ({ height, width, ...more }) => {

	return (
		<Send
			height={height}
			width={width}
			{...more}
		/>
	);
};

SendIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
};

export default SendIcon;
