import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Inputs from '../../../../components/mui/Inputs';
import RenewalModalComponent from './RenewalModalComponent';
import ClaimModalComponent from './ClaimModalComponent';
import { setIsOpenClaimModal, setIsOpenRenewalModal, setPolicy } from '../redux/actions/policyAction';
import usePermission from '../../../../hooks/usePermission';
import rentIcon from '../../../../assets/images/user.svg';
import calendarIcon from '../../../../assets/images/calendar.svg';

const PolicyCardComponent = ({ policy }) => {
  const {
    id,
    folio,
    monto_renta,
    fecha_inicio,
    fecha_termino,
    direccion_name,
    estado_pago,
    status
  } = policy;
  const hasClaim = usePermission('policies.claims.show');
  const hasRenewal = usePermission('policies.renewals.show');
  const dispatch = useDispatch();
  const [backgroundColor, setBackgroundColor] = useState('#$A0A0A0');

  useEffect(() => {
    onDisplayBackground();
  }, [
    id,
    folio,
    monto_renta,
    fecha_inicio,
    fecha_termino,
    direccion_name,
    estado_pago,
    status
  ]);

  /**
   *
   * @description Execute on display background color
   * @return dispatch
   *
   */
  const onDisplayBackground = async () => {
    switch (estado_pago) {
      case 0:
        return setBackgroundColor('#$A0A0A0');
      case 1:
        return setBackgroundColor('#1FD35C');
      case 2:
        return setBackgroundColor('#7E6060');
      case 5:
        return setBackgroundColor('#863992');
      case 6:
        return setBackgroundColor('#127D64');
      case 7:
        return setBackgroundColor('#3f78f8');
      case 8:
        return setBackgroundColor('#FD7017');
      case 9:
        return setBackgroundColor('#C66262');
      case 10:
        return setBackgroundColor('#7E6060');
    }
  }

  /**
   *
   * @description Execute open renewal modal state
   * @return dispatch
   *
   */
  const handleOpenClaimModal = async () => {
    if (hasClaim) {
      dispatch( setPolicy(policy) );
      dispatch( setIsOpenClaimModal(true) );
    }
  }

  /**
   *
   * @description Execute open claim modal state
   * @return dispatch
   *
   */
  const handleOpenRenewalModal = async () => {
    if (hasRenewal) {
      dispatch( setPolicy(policy) );
      dispatch( setIsOpenRenewalModal(true) );
    }
  }

  return (
    <>
      <div className="card p-0">
        <div className="card-header bg-dark-medium text-white">
          <div className="font-family-poppins-medium fs-18 mt-4 mb-4 text-center text-white">
            Folio { folio }
          </div>
        </div>
        <div className="card-body bg-white p-0 text-center">
          <div className="row m-0 p-0 pt-2 pb-2">
            <div className="col-sm-12 pt-4 pl-4 pr-4 pb-2">
              <div className="font-family-poppins-bold fs-16 text-dark text-uppercase">
                <img
                  alt=""
                  className="mr-2"
                  src={rentIcon}
                  width="20"
                />
                Renta Actual
              </div>
            </div>
            <div className="col-sm-12 pb-4">
              <div className="font-family-poppins-medium fs-16 text-dark text-wrap">
                ${ monto_renta }
              </div>
            </div>
          </div>
          <div className="row m-0 p-0 pt-2 pb-2 bg-light">
            <div className="col-sm-12 pt-4 pb-2">
              <div className="font-family-poppins-bold s-6 text-dark text-uppercase">
                <img
                  alt=""
                  className="mr-2"
                  src={calendarIcon}
                  width="20"
                />
                FECHA
              </div>
            </div>
            <div className="col-sm-12 pb-4">
              <div className="font-family-poppins-medium fs-14 text-dark text-uppercase">
                { fecha_termino }
              </div>
            </div>
            {hasRenewal && (
              <div className="col-sm-12 pb-4">
                <div
                  onClick={handleOpenRenewalModal}
                  className="font-family-poppins-semibold fs-16 text-info text-no-underline"
                >
                  Renovar ahora
                </div>
              </div>
            )}
          </div>
          <div className="row m-0 p-0 pt-2 pb-2">
            <div className="col-sm-12 pt-4 pl-4 pr-4 pb-2">
              <div className="font-family-poppins-medium fs-16 text-dark">
                Para más información favor de
              </div>
              <div className="font-family-poppins-medium fs-16 text-dark">
                comunicarse a su sucursal
              </div>
            </div>
            {hasClaim && (
              <div className="col-sm-12 pt-4 pl-4 pr-4 pb-2">
                <Inputs.Button
                  onClick={handleOpenClaimModal}
                  label="Iniciar Reclamación"
                  className="btn-outline-dark font-family-poppins-medium fs-16"
                  type="button"
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`card-footer p-0`}
          style={{backgroundColor: backgroundColor}}
        >
          <div className="row m-0 p-0">
            <div className="col-sm-12 p-4 text-center">
              <div className="font-family-poppins-semibold fs-18 mt-2 mb-2 text-white text-uppercase">
                { status }
              </div>
            </div>
          </div>
        </div>
      </div>
      <RenewalModalComponent />
      <ClaimModalComponent />
    </>
  );
};

PolicyCardComponent.propTypes = {
  policy: PropTypes.object.isRequired
};

export default PolicyCardComponent;
