export const initialValuesLogin = {
	email: '',
	password: ''
};

export const initialValuesProfile = {
	password: '',
	passwordConfirm: ''
};

export const initialValuesForgotPassword = {
	email: ''
};

export const initialValuesAuth = {
	firstName: '',
	lastName: '',
	email: '',
	roleType: '',
	userType: '',
	marketer: '',
	signerType: '',
	signer: '',
	status: ''
};

export const initialValuesClaim = {
	phone: '',
	subject: '',
	reason: ''
};

export const initialValuesRenewal = {
	rent: '',
	observation: ''
};
