import Avatar from './Avatar';
import Box from './Box';
import Card from './Card';
import CardActions from './CardActions';
import CardContent from './CardContent';
import CardHeader from './CardHeader';
import Chip from './Chip';
import Divider from './Divider';
import HeaderContent from './HeaderContent';
import IconButton from './IconButton';
import Icons from './Icons';
import Page from './Page';
import Pagination from './Pagination';
import Paper from './Paper';
import Table from './Table';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableRowLoading from './TableRowLoading';
import TableRowNoFound from './TableRowNoFound';
import TableSearch from './TableSearch';
import Tooltip from './Tooltip';
import Typography from './Typography';

const index = {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	HeaderContent,
	IconButton,
	Icons,
	Page,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableHead,
	TablePagination,
	TableRowLoading,
	TableRowNoFound,
	TableSearch,
	Tooltip,
	Typography
};

export default index;
