import React from 'react';
import { useSelector } from 'react-redux';
import Feedback from '../../../components/mui/Feedback';
import Navigation from '../../../components/mui/Navigation';
import Header from './header';
import ProtectedRoutes from '../../../routes/ProtectedRoutes';
import ModalProfileComponent from '../settings/UserPage/components/ModalProfileComponent';
import './LayoutPage.scss';

const LayoutPage = () => {
	const isLoading = useSelector( ({ loading }) => loading.isLoading);

	return (
		<Feedback.ScrollBar style={{ overflowX: 'hidden' }}>
			<Header />

			<div className="container-fluid container-top p-5">
				<ProtectedRoutes />
			</div>

			<Feedback.ModalLoading
				isOpen={isLoading}
				title="Cargando..."
				content="Espere un momento por favor..."
			/>

			<ModalProfileComponent />
			<Navigation.ScrollTop />
		</Feedback.ScrollBar>
	);
};

export default LayoutPage;
