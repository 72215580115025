import React from 'react';
import PropTypes from 'prop-types';
import Search from '@mui/icons-material/Search';

const SearchIcon = ({ height, width, ...more }) => {

	return (
		<Search
			height={height}
			width={width}
			{...more}
		/>
	);
};

SearchIcon.propTypes = {
	height: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired
};

export default SearchIcon;
