import * as types from '../types/policyType';

const initialState = {
  perPage: 20,
  page: 1,
  pages: 1,
  arrayList: [],
  policy: {},
  isOpenClaimModal: false,
  isOpenRenewalModal: false
};

const policyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.PER_PAGE_POLICY:
      return {
        ...state,
        perPage: payload
      };

    case types.PAGE_POLICY:
      return {
        ...state,
        page: payload
      };

    case types.PAGES_POLICY:
      return {
        ...state,
        pages: payload
      };

    case types.ARRAY_LIST_POLICY:
      return {
        ...state,
        arrayList: payload
      };

    case types.POLICY:
      return {
        ...state,
        policy: payload
      };

    case types.IS_OPEN_CLAIM_MODAL:
      return {
        ...state,
        isOpenClaimModal: payload
      };

    case types.IS_OPEN_RENEWAL_MODAL:
      return {
        ...state,
        isOpenRenewalModal: payload
      };

    default:
      return state;
  }
};

export default policyReducer;
