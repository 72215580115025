import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../redux/store';
import Feedback from '../../../../components/mui/Feedback';
import Logo from '../../../../components/mui/Logo';
import { setReset } from '../../../auth/redux/actions/authAction';
import { setIsOpenModal } from '../../settings/UserPage/redux/actions/profileAction';
import { setLogOut } from '../../../auth/services';
import swalErrors from '../../../../hooks/useErrors';
import usePermission from '../../../../hooks/usePermission';
import isSuccess from '../../../../helpers/isSuccess';
import toggleIcon from '../../../../assets/images/toggle.svg';
import logOutIcon from '../../../../assets/images/logout.svg';
import arrowLeftIcon from '../../../../assets/images/arrow-left.svg';

const Header = () => {
	const hasUpdatePassword = usePermission('users.updatePassword');
	const { firstName, lastName } = useSelector( ({ auth })  => auth.userData);
	const dispatch = useDispatch();
	const [isOpen, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleOpenSidebar = () => setOpen(true);

	const handleCloseSidebar = () => setOpen(false);

	const handleOpenProfile = () => {
		if (hasUpdatePassword) {
			dispatch( setIsOpenModal(true) );
		}
	};


	const handleLogOut = async () => {
		setIsLoading(true);

		try {
			const { data } = await setLogOut();

			if (isSuccess(data.status)) {
				dispatch( setReset() );
				localStorage.clear();
				handleCloseSidebar();
				await history.push('/');
			}
		} catch (error) {
			await swalErrors(error);
			setIsLoading(false);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<nav className="navbar bg-white position-fixed">
				<div className="container-fluid">
					<button
						onClick={handleOpenSidebar}
						className="navbar-toggler navbar-toggler-first"
						data-bs-toggle="collapse"
						data-bs-target="#navbarToggleExternalContent"
						aria-controls="navbarToggleExternalContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
						type="button"
					>
						<img
							alt=""
							src={toggleIcon}
							loading="lazy"
						/>
					</button>
					<div className="navbar-logo">
						<Logo className="img-logo" />
					</div>
				</div>
			</nav>

			<div className={`sidebar ${isOpen && 'expand'}`}>
				<header>
					<button
						onClick={handleCloseSidebar}
						className="close-sidedar"
						type="button"
					>
						<img
							alt=""
							src={arrowLeftIcon}
							loading="lazy"
						/>
					</button>
					<div className="title-sidebar">
						<h6 className="fs-16 font-family-poppins-bold text-dark text-center">
							Mi cuenta
						</h6>
					</div>
				</header>
				<div className="container-sidebar p-4">
					<div className="row">
						<div className="col-sm-12 mb-4">
							<div className="font-family-poppins-bold fs-16 text-dark text-wrap">
								Bienvenido, { firstName } { lastName }
							</div>
						</div>
						{hasUpdatePassword && (
							<div className="col-sm-12 mb-2">
								<a
									onClick={handleOpenProfile}
									className="font-family-poppins-semibold fs-14 text-no-underline text-dark"
								>
									Cambiar contraseña
								</a>
							</div>
						)}
						<div className="col-sm-12">
							<a
								onClick={handleLogOut}
								className="font-family-poppins-bold fs-14 text-no-underline text-danger"
							>
								<img
									alt=""
									src={logOutIcon}
									loading="lazy"
									width="20"
								/>
								<span className="ml-1">
									Salir
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<Feedback.ModalLoading
				isOpen={isLoading}
				title='Cerrando sesión...'
				content='Espere un momento por favor...'
			/>
		</>
	);
};

export default Header;
