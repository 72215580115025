import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import createReducer from './reducer';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'loading',
		'forgotPassword',
		'passwordReset',
		'policy'
	],
	transforms: [ ]
};

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];
const persistedReducer = persistReducer(persistConfig, createReducer());
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);

export { history, store, persistor };
