import React from 'react'
import PropTypes from 'prop-types'

const TableHead = ({ columns, className = '', fontClassName = '' }) => {

	return (
		<thead className={className === '' ? "table-dark align-middle align-self-center" : className }>
			{columns.length > 0 && (
				<tr>
					{columns.map(({ label, align, width }, number) => (
						<td
							key={number}
							align={align}
							width={width}
						>
							<div className={fontClassName === '' ? "font-family-roboto-medium fs-16" : fontClassName}>
								{label}
							</div>
						</td>
					))}
				</tr>
			)}
		</thead>
	)
}

TableHead.propTypes = {
	columns: PropTypes.array.isRequired,
	className: PropTypes.string,
	fontClassName: PropTypes.string
}

export default TableHead
