import Loading from './Loading';
import Modal from './Modal';
import ModalLoading from './ModalLoading';
import ScrollBar from './ScrollBar';
import Skeleton from './Skeleton';

const index = {
	Loading,
	Modal,
	ModalLoading,
	ScrollBar,
	Skeleton
};

export default index;
