import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { history } from '../../../../redux/store';
import Inputs from '../../../../components/mui/Inputs';
import Layout from '../../../../components/mui/Layout';
import { setForgotPassword } from '../../services';
import { setIsLoadingForm } from '../../redux/actions/forgotPasswordAction';
import { swalSuccess } from '../../../../hooks/useSweetAlert';
import swalErrors from '../../../../hooks/useErrors';
import isSuccess from '../../../../helpers/isSuccess';
import { initialValuesForgotPassword as initialValues } from '../../../../helpers/variablesInitialValues';

const FormComponent = () => {
	const dispatch = useDispatch();
	const isLoadingForm = useSelector( ({ forgotPassword }) => forgotPassword.isLoadingForm);
	
	/**
	 *
	 * @description Validation schema
	 * @return object
	 *
	 */
	const validationSchema = () => (
		Yup.object({
			email: Yup.string()
			.email('El email no es válido')
			.required('El email es requerido')
		})
	);

	/**
	 *
	 * @description Execute submit of the form
	 * @param values
	 * @param resetForm
	 * @param setValues
	 * @return dispatch
	 *
	 */
	const hanbleSubmit = async (values, { resetForm, setValues }) => {
		dispatch( setIsLoadingForm(true) );
	
		try {
			const { data } = await setForgotPassword(values);

			if (isSuccess(data.status)) {
				resetForm({ values: initialValues});
				setValues(initialValues);
				await swalSuccess('Solicitud exitosa', data.data);
				await history.push('/');
			}
		} catch (error) {
			await swalErrors(error);
			dispatch( setIsLoadingForm(false) );
		} finally {
			dispatch( setIsLoadingForm(false) );
		}
	};
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={hanbleSubmit}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				isValid,
				dirty
			}) => (
				<Form noValidate>
					<div className="row">
						<div className="col-sm-12 mb-4">
							<Inputs.TextBox
								name="email"
								label="* Email"
								placeholder="Ingrese email..."
								value={values.email}
								onBlur={handleBlur}
								onChange={handleChange}
								hasError={errors.email && touched.email ? true : false}
								errorMessage={errors.email && touched.email ? errors.email : ''}
								className="font-family-poppins-regular"
							/>
						</div>
						<div className="col-sm-12 d-flex justify-content-center">
							<Inputs.LoadingButton
								isDisabled={!(dirty && isValid)}
								isLoading={isLoadingForm}
								isLoadingPosition="start"
								startIcon={<Layout.Icons.MailIcon height={18} width={18} />}
								className={`${(dirty && isValid) ? 'btn-primary' : 'btn-grey'} font-family-poppins-medium fs-16`}
								label="Cambiar contraseña"
								type="submit"
							/>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default FormComponent;
