import React from 'react'
import PropTypes from 'prop-types'
import MuiPaper from '@mui/material/Paper'

const Paper = ({ children, ...more  }) => {
	
	return (
		<MuiPaper
			{...more}
		>
			{children}
		</MuiPaper>
	)
}

Paper.propTypes = {
	children: PropTypes.any.isRequired
}

export default Paper
