import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import PolicyPage from '../pages/dashboard/PolicyPage';

const ProtectedRoutes = () => {
  const routes = [
    {
      index: false,
      path: 'polizas',
      element: <PolicyPage />
    }
  ];

  return (
    <Routes>
      {routes.length > 0 &&
        routes.map(({ permission, index, path, element }, number) => (
          <Fragment key={number}>
            <Route
              index={index}
              path={path}
              element={element}
            />
          </Fragment>
        ))
      }
    </Routes>
  );
};

export default ProtectedRoutes;
