import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

const KeyboardArrowUpIcon = ({ height, width, ...more }) => {

  return (
    <KeyboardArrowUp
      height={height}
      width={width}
      {...more}
    />
  );
};

KeyboardArrowUpIcon.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

export default KeyboardArrowUpIcon;
