import React from 'react';
import Layout from '../../../components/mui/Layout';
import Navigation from '../../../components/mui/Navigation';
import HeaderComponent from '../components/HeaderComponent'
import FormComponent from './components/FormComponent';
import '../Auth.scss';

const ForgotPasswordPage = () => {
	return (
		<Layout.Page title="Forgot Password">
			<div className="form-container">
				<div className="card">
					<div className="card-body">
						<HeaderComponent />
						<div className="row">
							<div className="col-sm-12 mb-4">
								<FormComponent />
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-12 text-center'>
								<Navigation.NavLink
									to="/"
									className='font-family-roboto-regular fs-6 text-primary text-underline'
								>
									Volver al inicio de sesión
								</Navigation.NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout.Page>
	);
};

export default ForgotPasswordPage;
