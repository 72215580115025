import { initialValuesAuth as initialValues } from '../../../../helpers/variablesInitialValues';
import * as types from '../types/authType';
import { setResetForgotPassword } from './forgotPasswordAction';
import { setProfileReset } from '../../../dashboard/settings/UserPage/redux/actions/profileAction';
import { setResetLoading } from '../../../../redux/loading/actions/loadingAction';
import { setPolicyReset } from '../../../dashboard/PolicyPage/redux/actions/policyAction';

/**
 *
 * @description Set reset global state
 * @return dispatch
 *
 */
export const setReset = () => (
	async (dispatch) => {
		dispatch( setResetForgotPassword(false) );
		dispatch( setIsLoadingForm(false) );
		dispatch( setIsAuthenticated(false) );
		dispatch( setTokenType('') );
		dispatch( setAccessToken('') );
		dispatch( setExpiresAt('') );
		dispatch( setUserData(initialValues) );
		dispatch( setProfileReset() );
		dispatch( setResetLoading() );
		dispatch( setPolicyReset() );
	}
);

/**
 *
 * @description Set loadingForm state
 * @param value
 * @return object
 *
 */
export const setIsLoadingForm = value => ({
	type: types.IS_LOADING_FORM_AUTH,
	payload: value
});

/**
 *
 * @description Set authenticated state
 * @param value
 * @return object
 *
 */
export const setIsAuthenticated = value => ({
	type: types.IS_AUTHENTICATED,
	payload: value
});

/**
 *
 * @description Set tokenType state
 * @param value
 * @return object
 *
 */
export const setTokenType = value => ({
	type: types.TOKEN_TYPE,
	payload: value
});

/**
 *
 * @description Set accessToken state
 * @param value
 * @return object
 *
 */
export const setAccessToken = value => ({
	type: types.ACCESS_TOKEN,
	payload: value
});

/**
 *
 * @description Set expiresAt state
 * @param value
 * @return object
 *
 */
export const setExpiresAt = value => ({
	type: types.EXPIRES_AT,
	payload: value
});

/**
 *
 * @description Set userData state
 * @param value
 * @return object
 *
 */
export const setUserData = value => ({
	type: types.USER_DATA,
	payload: value
});
